import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputIncreaseDecrease } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { currencySymbol } from '../../../utils/helpers';
import subscription from '../../../utils/api/subscription';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function StorageChangeModal(props) {
  const dispatch = useDispatch();
  const {
    submitStorageCount,
    closeModal,
    storageAdd,
    totalStorage,
    currency,
    viewStorageSummary,
  } = props;
  const { t } = useTranslation();
  const [storageCount, setStorageCount] = useState(storageAdd);
  const [disable, setDisable] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleInputChange = (name, changeValue) => {
    const req = {
      storageCountChange: changeValue - totalStorage,
    };
    subscription.calculatePrice(req).then((res) => {
      setStorageCount((prevValue) => ({
        ...prevValue,
        [name]: changeValue - totalStorage,
        totalStorage: changeValue,
        price: res?.data?.additionalStoragePrice,
        unitPrice: res?.data?.perStoragePrice,
      }));
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
  };
  const back = () => closeModal(false);
  const submitDevice = () => {
    setButtonLoading(true);
    const request = {
      storage: storageCount?.totalStorage,
    };
    subscription.storageIncreaseCountSummary(request).then((response) => {
      setButtonLoading(false);
      const payment = {
        planName: response?.data?.plan_name,
        unitPrice: response?.data?.unit_price,
        storageCount: response?.data?.quantity,
        totalPrice: response?.data?.price,
        paymentSummary: response?.data?.payment_summary,
        actualPrice: response?.data?.actual_price,
      };
      if (payment?.totalPrice > 0) {
        submitStorageCount(storageCount, payment);
      } else {
        viewStorageSummary(storageCount, payment);
      }
    }).catch((error) => {
      setButtonLoading(false);
      dispatch(setErrorNotification(error?.response?.data));
      // backModal();
    });
  };
  useEffect(() => {
    if (storageCount.totalStorage !== totalStorage) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [storageCount]);
  return (
    <div className="display-information">
      <div className="display-form">
        <div className="field">
          <InputIncreaseDecrease
            icon={' '}
            label={t('storage')}
            type="number"
            name="storage"
            required={false}
            value={storageCount.totalStorage}
            change={handleInputChange}
            placeholder="0"
            min={0}
          />
        </div>
        <div className="field-price">
          {storageCount.storage} * {currencySymbol(currency, storageCount.unitPrice)}/{t('gb')} =
          {currencySymbol(currency, storageCount.price)}
        </div>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('confirm')}
            click={submitDevice}
            classes={buttonLoading
              ? 'success-button loading-btn'
              : 'success-button'}
            disabled={disable}
            loading={buttonLoading}
          />
        </div>
      </div>
    </div>
  );
}

StorageChangeModal.propTypes = {
  submitStorageCount: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  totalStorage: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  storageAdd: PropTypes.shape({
    storage: PropTypes.number,
    totalStorage: PropTypes.number,
    price: PropTypes.number,
  }),
  viewStorageSummary: PropTypes.func,
};
StorageChangeModal.defaultProps = {
  storageAdd: {},
  viewStorageSummary: () => { },
};

export default StorageChangeModal;
