import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Country, State, City } from 'country-state-city';
import { Input } from '../../../../shared/component/form/Input';
import Button from '../../../../shared/component/Button';
import ModalBox from '../../../../shared/component/ModalBox';
import SelectBox from '../../../../shared/component/form/SelectBox';
import AutocompleteBox from '../../../../shared/component/form/AutoComplete';
import DeleteRequestPopup from './DeleteAccountPopup';
import EmailVerification from './EmailVerification';
import OtpVerification from './OtpVerification';
import DeleteConfirmation from './DeleteConfirmation';
import {
  descriptionValidation,
  emailValidation,
  nameValidation,
  pincodeValidation,
  textNumberValidation,
} from '../../../../shared/component/form/Validation';
import company from '../../../../utils/api/company';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import Success from '../../../../shared/component/Success';
import { setLocalStorageItem } from '../../../../utils/helpers';

function Company() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState({});
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [uploading, setUploading] = useState(false);
  const [modalView, setModalView] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [notification, setNotification] = useState(false);
  const localData = JSON.parse(localStorage.getItem('data'));
  let emailVerification;
  let verifyOtp;

  const handleChange = (name, changeValue) => {
    if (name === 'country_id') {
      if (changeValue) {
        setValue((prevValue) => ({
          ...prevValue,
          country: changeValue.label,
          state: '',
          city: '',
        }));
        setCountryCode(changeValue.id);
        const state1 = State.getStatesOfCountry(changeValue.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setState(state1);
      }
    } else if (name === 'state_id') {
      if (changeValue) {
        const city1 = City.getCitiesOfState(countryCode, changeValue.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCity(city1);
        setValue((prevValue) => ({ ...prevValue, state: changeValue.label, city: '' }));
      }
    } else if (name === 'city_id') {
      if (changeValue) {
        setValue((prevValue) => ({ ...prevValue, city: changeValue.label }));
      }
    } else {
      setValue({ ...value, [name]: changeValue });
    }
  };

  const deleteConfirmation = (email, otp) => {
    setModalView({
      title: 'Delete Account',
      content: <DeleteConfirmation
        closeModal={setOpenModal}
        back={verifyOtp}
        email={email}
        otp={otp}
      />,
    });
  };

  verifyOtp = (email, otp) => {
    setModalView({
      title: 'OTP Verification',
      content: <OtpVerification
        email={email}
        otp={otp}
        back={emailVerification}
        deleteConfirmation={deleteConfirmation}
      />,
    });
  };

  emailVerification = (email) => {
    setModalView({
      title: 'Email Verification',
      content: <EmailVerification
        closeModal={setOpenModal}
        verifyOtp={verifyOtp}
        email={email}
      />,
    });
  };

  const deleteAccount = () => {
    /* temp */
    console.log(localData, 'localData');
    if (localData.user?.company?.subscription?.status !== 'active') {
      emailVerification();
    } else {
      setModalView({
        title: 'Delete Request',
        content: <DeleteRequestPopup
          closeModal={setOpenModal}
        />,
      });
    }
    setOpenModal(true);
  };

  const submitCompany = () => {
    setIsSubmitted(true);
    if (
      nameValidation(1, 100, value?.name)
      && textNumberValidation(0, 50, value?.tax_id)
      && emailValidation(value?.company_email)
      && descriptionValidation(255, value?.address)
      && pincodeValidation(value?.pincode)
    ) {
      setUploading(true);
      const request = {
        company_name: value?.name,
        company_email: value?.company_email,
        tax_id: value?.tax_id,
        address: value?.address,
        country: value?.country,
        state: value?.state,
        city: value?.city,
        pincode: value?.pincode,
      };
      company.updateCompany(request)
        .then((res) => {
          console.log(res?.data);
          setLocalStorageItem('data', {
            ...localData,
            user: {
              ...localData?.user,
              company: {
                ...localData?.user?.company,
                name: res?.data?.name,
                email: res?.data?.email,
                tax_id: res?.data?.tax_id,
                address: res?.data?.address,
                country: res?.data?.country,
                state: res?.data?.state,
                city: res?.data?.city,
                pincode: res?.data?.pincode,
              },
            },
          });
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, 3000);
        })
        .catch((err) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  useEffect(() => {
    const country = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
    setCountries(country);
    console.log(localData?.user?.company);
    setValue({
      ...localData?.user?.company,
      company_email: localData.user?.company.email,
    });
  }, []);

  useEffect(() => {
    const country1 = Country.getAllCountries().find((val) => val.name === value?.country);
    setCountryCode(country1?.isoCode);
    const state1 = State.getStatesOfCountry(country1?.isoCode).map((val) => ({ id: val.isoCode, label: val.name }));
    setState(state1);
    const city1 = City.getCitiesOfState(country1?.isoCode, state1
      .find((val) => val.label === value?.state)?.id)
      .map((val) => ({ id: val.isoCode, label: val.name }));
    setCity(city1);
    if (value?.company_email
      && value?.name
      && value?.address
      && value?.country
      && value?.city
      && value?.pincode
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  return (
    <>
      <ModalBox
        status={openModal}
        closeModal={setOpenModal}
        modalView={modalView}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('companyUpdatedSuccess')} /> }}
      />
      <div className="user-form company-form">
        <h1 className="title">{t('companyInformation')}</h1>
        <div className="single-row">
          <Input
            icon={' '}
            label={t('account')}
            type="text"
            name="name"
            required
            value={value?.name}
            change={handleChange}
            placeholder="Enter Name"
            error={!nameValidation(1, 50, value?.name) && isSubmitted}
            errorMessage={t('companyUserFullNameValidation')}
          />
          <Input
            icon={' '}
            label={t('taxId')}
            type="text"
            name="tax_id"
            value={value?.tax_id}
            change={handleChange}
            placeholder="Enter taxId"
            error={!textNumberValidation(0, 50, value?.tax_id) && isSubmitted}
            errorMessage={t('taxIdValidation')}
          />
        </div>
        <div className="single-row">
          <Input
            icon={' '}
            label={t('companyEmail')}
            type="text"
            name="company_email"
            value={value?.company_email}
            change={handleChange}
            placeholder="Enter email"
            required
            error={!emailValidation(value?.company_email) && isSubmitted}
            errorMessage={t('invalidEmail')}
          />
        </div>
        <Input
          icon={' '}
          label={t('address')}
          type="text"
          name="address"
          value={value?.address}
          change={handleChange}
          placeholder="Enter address"
          required
          error={!descriptionValidation(255, value?.address) && isSubmitted}
          errorMessage={t('addressValidation')}
        />
        <div className="single-row">
          <SelectBox
            label={t('country')}
            name="country_id"
            required
            value={value?.country}
            onchange={handleChange}
            lists={countries}
            id="controllable-country-demo"
            placeholder={t('selectCountry')}
          />
          <AutocompleteBox
            label={t('state')}
            name="state_id"
            required
            value={value?.state}
            onchange={handleChange}
            lists={state}
            id="controllable-state-demo"
            placeholder={t('selectState')}
            allowNewOption
          />
        </div>
        <div className="single-row">
          <AutocompleteBox
            label={t('city')}
            name="city_id"
            required
            value={value?.city}
            onchange={handleChange}
            lists={city}
            id="controllable-city-demo"
            placeholder={t('selectCity')}
            allowNewOption
          />
          <Input
            icon={' '}
            label={t('pinCode')}
            type="text"
            name="pincode"
            value={value?.pincode}
            change={handleChange}
            placeholder="Enter pincode"
            required
            error={!pincodeValidation(value?.pincode) && isSubmitted}
            errorMessage={t('invalidPincode')}
          />
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('deleteAccount')} click={deleteAccount} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('loading') : t('saveChanges')}
              click={submitCompany}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
              disabled={disable}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Company;
