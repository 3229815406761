import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TopContent from '../../shared/component/TopContent';
import {
  currencySymbol,
  capitalize,
  noDays,
  convertToMBorGB,
  checkPermission,
  setLocalStorageItem,
} from '../../utils/helpers';

import {
  setErrorNotification,
} from '../../redux/slices/NotificationSlice';

import StorageStatus from './components/StorageStatus';
import CardStatus from './components/CardStatus';
import SubscriptionSummary from './components/SubscriptionSummary';
import TransactionHistory from './components/TransactionHistory';

import { DisplaySignageIcon, PlanIcon, WalletIcon } from '../../shared/component/svgIcon';
import ModalBox from '../../shared/component/ModalBox';
import subscription from '../../utils/api/subscription';
import RentalHistory from './components/RentalHistory';
import DisplayIncreaseModal from './components/DisplayIncreaseModal';
import PlansPricing from './components/PlansPricing';
import PlanSwitchSummary from './components/PlanSwitchSummary';
import DeviceChangeSummary from './components/DeviceChangeSummary';
import Success from '../../shared/component/Success';
import StorageChangeModal from './components/StorageChangeModal';
import StorageChangeSummary from './components/StorageChangeSummary';
import PlanSwitchPaymentMode from './components/PlanSwitchPaymentMode';
import DeviceChangePaymentMode from './components/DeviceChangePaymentMode';
import StorageChangePaymentMode from './components/StorageChangePaymentMode';
import {
  getSubscriptionBillingDetails,
  resetDevicePayment,
  resetStoragePayment,
} from '../../redux/slices/SubscriptionSlice';

function Subscriptions() {
  const localData = JSON.parse(localStorage.getItem('data'));
  const menus = [
    {
      name: 'Subscriptions',
      className: checkPermission('getSubscription') ? 'menus active' : 'menus',
      enable: checkPermission('getSubscription'),
    },
    {
      name: 'Transactions',
      className: !checkPermission('getSubscription') ? 'menus active' : 'menus',
      enable: checkPermission('getTransaction'),
    },
  ];
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [cardData, setCardData] = useState({
    device: {},
    planName: 'Plan',
    remainingDays: 0,
    storage: {},
    wallet: 0,
  });

  let deviceModal;
  let storageModal;
  let devicePaymentMode;
  let storagePaymentMode;

  const [modalOpen, setModalOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const [modalView, setModalView] = useState({});

  const [tabs, setTabs] = useState(menus);
  const [tableHeader, setTableHeader] = useState(checkPermission('getSubscription') ? 'Subscriptions' : 'Transactions');

  const getTotalContentSize = () => {
    subscription.getSubscriptionDetails()
      .then((res) => {
        setCardData(res?.data);
        dispatch(getSubscriptionBillingDetails([]));
        if (res?.data?.status === 'active') {
          /* we need to change the localStorage value without using Helper function */
          localStorage.setItem('showSubscriptionPrompt', JSON.stringify(false));
          /* We need to update the subscription status based on the response once it becomes 'active',
          as we can store the status in local storage. */
          setLocalStorageItem('data', {
            ...localData,
            user: {
              ...localData?.user,
              company: {
                ...localData?.user?.company,
                subscription: {
                  ...localData?.user?.company?.subscription,
                  status: res?.data?.status,
                  start_date: res?.data?.start_date,
                  end_date: res?.data?.end_date,
                },
              },
            },
          });
        }
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response ? error?.response?.data : error));
      });
  };

  const changeActiveTab = (name) => {
    const lists = tabs.map((e) => (
      { ...e, className: e.name === name ? 'menus active' : 'menus' }
    ));
    setTabs(lists);
    setTableHeader(name);
  };

  // Show success notification when plan switch is success
  const showNotification = (type) => {
    let successMessage;
    if (type === 'plan') {
      successMessage = <Success message={t('planChangedSuccess')} />;
    } else if (type === 'device') {
      successMessage = <Success message={t('deviceChangedSuccess')} />;
    } else if (type === 'storage') {
      successMessage = <Success message={t('storageChangedSuccess')} />;
    }

    setNotification(true);
    setModalView({
      title: '',
      content: successMessage,
      notification: true,
    });
    setTimeout(() => {
      setNotification(false);
      setModalOpen(false);
      getTotalContentSize();
    }, 3000);
  };

  const viewStorageSummary = (data, payment) => {
    setModalView({
      title: t('orderSummary'),
      content: <StorageChangeSummary
        backModal={() => {
          if (payment?.totalPrice > 0) {
            storagePaymentMode(data, payment);
          } else {
            storageModal(data);
          }
        }}
        storageChangeSuccess={showNotification}
        storageInfo={data}
        currency={cardData?.currency}
        paymentData={payment}
      />,
    });
  };

  storagePaymentMode = (data, payment) => {
    setModalView({
      title: t('orderSummary'),
      content: <StorageChangePaymentMode
        backModal={() => storageModal(data)}
        storageChangeSuccess={showNotification}
        storageInfo={data}
        payment={payment}
        // paymentInfo={paymentInfo}
        viewStorageSummary={viewStorageSummary}
        companyDetails={cardData?.companyData}
        currency={cardData?.currency}
        closeModal={setModalOpen}
      />,
    });
  };

  storageModal = (storageAdd) => {
    setNotification(false);
    setModalOpen(true);
    setModalView({
      title: t('storageInformation'),
      content: <StorageChangeModal
        closeModal={setModalOpen}
        storageAdd={storageAdd}
        totalStorage={cardData?.storage?.addOnSubscriptionStorage || 0}
        currency={cardData?.currency}
        storagePrice={cardData?.devicePrice}
        submitStorageCount={storagePaymentMode}
        viewStorageSummary={viewStorageSummary}
      />,
    });
    dispatch(resetStoragePayment());
  };

  // Function to handle plan change summary popup
  const handleChangePlanSummary = (planId, tenure, planSwitchSummary) => {
    setModalView({
      title: t('orderSummary'),
      content: <PlanSwitchSummary
        newPlanId={planId}
        planSwitchTenure={tenure}
        planSwitchSummary={planSwitchSummary}
        subscriptionStatus={cardData?.status}
        planSwitchSuccess={showNotification}
        backModal={setModalOpen}
      />,
      notification: false,
    });
  };

  const handleChangePaymentMode = (planId, tenure, setButtonLoading) => {
    subscription.getPlanChangeSummary({
      new_plan_id: planId,
      tenure,
    })
      .then((res) => {
        setButtonLoading((prevState) => ({
          ...prevState,
          [planId]: false,
        }));
        const planSwitchSummary = res?.data;
        if (planSwitchSummary?.payment_summary?.amount_to_paid > 0) {
          setModalView({
            title: t('orderSummary'),
            content: <PlanSwitchPaymentMode
              newPlanId={planId}
              planSwitchTenure={tenure}
              planSwitchSummary={planSwitchSummary}
              subscriptionStatus={cardData?.status}
              handleChangePlanSummary={handleChangePlanSummary}
              backModal={setModalOpen}
            />,
            notification: false,
          });
        } else {
          handleChangePlanSummary(planId, tenure, planSwitchSummary);
        }
      })
      .catch((err) => {
        setButtonLoading((prevState) => ({
          ...prevState,
          [planId]: false,
        }));
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // Function to open plan & pricing listing popup
  const handleChangePlan = () => {
    setModalView({
      title: t('plansPricing'),
      content: <PlansPricing
        handleChangePaymentMode={handleChangePaymentMode}
        backModal={setModalOpen}
      />,
      notification: false,
    });
    setModalOpen(true);
  };

  const viewSummary = (data, payment) => {
    setModalView({
      title: t('orderSummary'),
      content: <DeviceChangeSummary
        backModal={() => {
          if (payment?.totalPrice > 0) {
            devicePaymentMode(data, payment);
          } else {
            deviceModal(data);
          }
        }}
        deviceChangeSuccess={showNotification}
        deviceInfo={data}
        currency={cardData?.currency}
        paymentData={payment}
      />,
    });
  };

  devicePaymentMode = (data, payment) => {
    setModalView({
      title: t('orderSummary'),
      content: <DeviceChangePaymentMode
        backModal={() => deviceModal(data)}
        deviceInfo={data}
        payment={payment}
        currency={cardData?.currency}
        viewSummary={viewSummary}
        closeModal={setModalOpen}
      />,
    });
  };

  deviceModal = (deviceAdd) => {
    setNotification(false);
    setModalOpen(true);
    setModalView({
      title: t('deviceInformation'),
      content: <DisplayIncreaseModal
        closeModal={setModalOpen}
        deviceAdd={deviceAdd}
        totalDevice={cardData?.device?.subscriptionDeviceCount}
        currentUsedDevice={cardData?.device?.companyTotalActiveDevice}
        currency={cardData?.currency}
        submitDeviceCount={devicePaymentMode}
        viewSummary={viewSummary}
      />,
    });
    dispatch(resetDevicePayment());
  };

  useEffect(() => {
    getTotalContentSize();
  }, []);

  return (
    <div className="content">
      <ModalBox
        status={modalOpen}
        closeModal={setModalOpen}
        modalView={modalView}
        notification={notification}
      />
      <div className="main-content">
        <TopContent
          label={t('subscription')}
        />
        <div className="content-area-subscription">
          <div className="my-content-cards">
            <div
              className="card"
            >
              <CardStatus
                title={capitalize(cardData?.planName)}
                subTitle={
                  (
                    <div>
                      {
                        (() => {
                          let plan;
                          if (cardData?.status === 'expired') {
                            plan = <span className="text-danger">{t('planExpired')}</span>;
                          } else if (cardData?.status === 'cancelled') {
                            plan = <span className="text-danger">{t('planCancelled')}</span>;
                          } else {
                            plan = (
                              <span
                                style={{
                                  color: cardData?.remainingDays <= 3
                                    && cardData?.remainingDays > 0 ? '#F19702' : '',
                                }}
                              >
                                {t('planExpiresIn')} {noDays(cardData?.remainingDays, t)}
                              </span>
                            );
                          }
                          return plan;
                        })()
                      }
                    </div>
                  )
                }
                icon={<PlanIcon />}
              />
              <hr />
              <div className="footer-card">
                <div onClick={handleChangePlan} role="presentation" className="text-button">
                  {
                    (() => {
                      let message;
                      if (cardData?.status === 'expired' || cardData?.remainingDays <= 3) {
                        message = <span>{t('upgradePlan')}</span>;
                      } else if (cardData?.status === 'cancelled') {
                        message = <span>{t('renewPlan')}</span>;
                      } else {
                        message = <span>{t('change')}</span>;
                      }
                      return message;
                    })()
                  }
                </div>
              </div>
            </div>
            <div className="card">
              <CardStatus
                title={t('devices')}
                subTitle={
                  `${cardData?.device?.companyTotalActiveDevice || 0}
                  /
                  ${cardData?.device?.subscriptionDeviceCount || 0} ${t('devices')}`
                }
                icon={<DisplaySignageIcon />}
              />
              <hr />
              {!cardData?.trial_plan
                && (
                  <div className="footer-card">
                    <div
                      onClick={() => deviceModal({
                        totalDevice: cardData?.device?.subscriptionDeviceCount,
                        device: 0,
                        price: 0,
                      })}
                      role="presentation"
                      className="text-button"
                    >
                      {t('change')}
                    </div>
                  </div>
                )}
            </div>
            <div className="card">
              <StorageStatus
                usedStorage={convertToMBorGB(cardData?.storage?.companyUsedStorage || 0)}
                totalStorage={convertToMBorGB(cardData?.storage?.subscriptionStorage || 0)}
                companyUsedStorage={cardData?.storage?.companyUsedStorage || 0}
                subscriptionStorage={cardData?.storage?.subscriptionStorage || 0}
                title={t('storage')}
              />
              <hr />
              {!cardData?.trial_plan
                && (
                  <div className="footer-card">
                    <div
                      onClick={() => storageModal({
                        totalStorage: cardData?.storage?.addOnSubscriptionStorage || 0,
                        storage: 0,
                        price: 0,
                      })}
                      role="presentation"
                      className="text-button"
                    >
                      {t('change')}
                    </div>
                  </div>
                )}
            </div>
            <div className="card">
              <CardStatus
                title={t('wallet')}
                subTitle={`${currencySymbol(cardData?.currency || 'USD')} ${(cardData?.wallet)?.toFixed(2)}`}
                icon={<WalletIcon />}
              />
            </div>
          </div>
          <div className="my-content">
            <div className="tab-bar" style={{ alignItems: 'center' }}>
              <ul className="tab-bar-list">
                {
                  tabs.map((e) => e.enable && (
                    <li className={e.className}>
                      <div role="presentation" onClick={() => changeActiveTab(e.name)}>{t(e.name)}</div>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="my-content-area">
              {
                tableHeader === 'Subscriptions' && checkPermission('getSubscription') && (
                  <SubscriptionSummary />
                )
              }
              {
                tableHeader === 'Transactions' && checkPermission('getTransaction') && (
                  <TransactionHistory />
                )
              }
              {
                tableHeader === 'Rentals' && (
                  <RentalHistory />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
